import moment from "moment";
import { P6Timestamp } from "../../../src/types/helpers";

// long date
export function longDate(date: P6Timestamp) {
  const jsDate = new Date(
    date._seconds ? date._seconds * 1000 : date.seconds * 1000,
  );
  if (date) {
    const dateObj = jsDate;
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(dateObj.getTime());
  }
}

// short date
export function shortDate(date: P6Timestamp) {
  const jsDate = new Date(
    date._seconds ? date._seconds * 1000 : date.seconds * 1000,
  );
  if (date) {
    const dateObj = jsDate;
    return new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(dateObj.getTime());
  }
}

export function fromNow(date: P6Timestamp) {
  if (!date) {
    return;
  }

  const jsDate = new Date(date.seconds * 1000);
  if (date) {
    return moment(jsDate).fromNow();
  }
}
